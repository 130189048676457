'use client';

import Link from 'next/link';
import LogoNew from './LogoNew';
import LogoutButton from '../auth/LogoutButton';
import { usePathname, useRouter } from 'next/navigation';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useLocale } from 'next-intl';
import { useTranslations } from 'next-intl';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { ChevronDown, LogIn } from 'lucide-react';
import { User } from '@supabase/supabase-js';
export default function Navigation({
  user,
  isOnlyConsumer
}: {
  user: User | null;
  isOnlyConsumer: boolean;
}) {
  const pathname = usePathname();
  const locale = useLocale();
  const router = useRouter();
  const t = useTranslations('navigation');
  if (pathname.startsWith('/dash') || pathname.startsWith('/admin')) {
    return null;
  }
  return <header className="container" data-sentry-component="Navigation" data-sentry-source-file="Navigation.tsx">
      <nav className="flex items-center justify-between py-4 bg-background border-b">
        <Link href="/" data-sentry-element="Link" data-sentry-source-file="Navigation.tsx">
          <span className="sr-only">Courtica</span>
          <LogoNew mode="dark" data-sentry-element="LogoNew" data-sentry-source-file="Navigation.tsx" />
        </Link>
        <div className="flex items-center space-x-2">
          <Select name="language_switcher" defaultValue={locale} onValueChange={val => {
          document.cookie = `COURTICA_LOCALE=${val}; path=/; max-age=31536000`;
          document.cookie = `NEXT_LOCALE=${val}; path=/; max-age=31536000`;
          router.refresh();
        }} data-sentry-element="Select" data-sentry-source-file="Navigation.tsx">
            <SelectTrigger className="w-auto border-none" id="language_switcher" data-sentry-element="SelectTrigger" data-sentry-source-file="Navigation.tsx">
              <SelectValue placeholder="RO" className="pr-4" data-sentry-element="SelectValue" data-sentry-source-file="Navigation.tsx" />
            </SelectTrigger>
            <SelectContent data-sentry-element="SelectContent" data-sentry-source-file="Navigation.tsx">
              <SelectItem className="cursor-pointer" value="ro" data-sentry-element="SelectItem" data-sentry-source-file="Navigation.tsx">
                RO
              </SelectItem>
              <SelectItem className="cursor-pointer" value="en" data-sentry-element="SelectItem" data-sentry-source-file="Navigation.tsx">
                EN
              </SelectItem>
              <SelectItem className="cursor-pointer" value="ru" data-sentry-element="SelectItem" data-sentry-source-file="Navigation.tsx">
                RU
              </SelectItem>
            </SelectContent>
          </Select>
          {!!user ? <UserProfileNav email={user.email || 'placeholder@mail.com'} isOnlyConsumer={isOnlyConsumer} /> : <Link href="/login" className="leading-6">
              <Button variant="ghost" className="bg-slate-100">
                {t('login')} <LogIn className="ml-2" aria-hidden="true" size={'18px'} />
              </Button>
            </Link>}
        </div>
      </nav>
    </header>;
}
interface UserProfileNavProps {
  email: string;
  isOnlyConsumer: boolean;
}
export function UserProfileNav({
  email,
  isOnlyConsumer
}: UserProfileNavProps) {
  const t = useTranslations('navigation');
  const getInitials = (email: string) => {
    return email.split('@')[0].split('.').map(name => name[0].toUpperCase()).join('').slice(0, 2);
  };
  return <DropdownMenu data-sentry-element="DropdownMenu" data-sentry-component="UserProfileNav" data-sentry-source-file="Navigation.tsx">
      <DropdownMenuTrigger asChild data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="Navigation.tsx">
        <Button variant="ghost" className="relative h-8 w-full justify-start rounded-full text-sm font-medium transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50" data-sentry-element="Button" data-sentry-source-file="Navigation.tsx">
          <Avatar className="h-8 w-8" data-sentry-element="Avatar" data-sentry-source-file="Navigation.tsx">
            <AvatarFallback data-sentry-element="AvatarFallback" data-sentry-source-file="Navigation.tsx">{getInitials(email)}</AvatarFallback>
          </Avatar>
          <span className="ml-2 hidden md:inline">{email}</span>
          <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" data-sentry-element="ChevronDown" data-sentry-source-file="Navigation.tsx" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-56" data-sentry-element="DropdownMenuContent" data-sentry-source-file="Navigation.tsx">
        <Link href="/dash/bookings" data-sentry-element="Link" data-sentry-source-file="Navigation.tsx">
          <DropdownMenuItem className="py-3 cursor-pointer" data-sentry-element="DropdownMenuItem" data-sentry-source-file="Navigation.tsx">{t('dashboard')}</DropdownMenuItem>
        </Link>
        {!isOnlyConsumer && <Link href="/admin">
            <DropdownMenuItem className="py-3 cursor-pointer">{t('admin_panel')}</DropdownMenuItem>
          </Link>}
        <DropdownMenuSeparator data-sentry-element="DropdownMenuSeparator" data-sentry-source-file="Navigation.tsx" />
        <DropdownMenuItem className="py-3 cursor-pointer text-red-600 focus:bg-red-50 focus:text-red-600" data-sentry-element="DropdownMenuItem" data-sentry-source-file="Navigation.tsx">
          <LogoutButton className="text-red" data-sentry-element="LogoutButton" data-sentry-source-file="Navigation.tsx" />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>;
}