'use client';

import { Loader2, LogOut } from 'lucide-react';
import { useTransition } from 'react';
import { useTranslations } from 'next-intl';
import { logout } from './actions';
import { cn } from '@/lib/utils';
export default function LogoutButton({
  className = ''
}: {
  className: string;
}) {
  const t = useTranslations('auth');
  const [isPending, startTransition] = useTransition();
  const onLogoutClick = async () => {
    startTransition(async () => {
      await logout();
    });
  };
  return <button onClick={onLogoutClick} className={cn('flex items-center', className)} data-sentry-component="LogoutButton" data-sentry-source-file="LogoutButton.tsx">
      <LogOut className="mr-2 h-4 w-4" data-sentry-element="LogOut" data-sentry-source-file="LogoutButton.tsx" />
      {isPending ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : t('logout')}
    </button>;
}